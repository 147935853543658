// Gab Table
.gapDescription {
    margin-bottom: 5px;
    display: flex;
    padding: 7px;

    h4 {
        margin-right: 10px !important;
    }
}

// Gap Progress Bar CSS

// @keyframes progress {
//     0% {
//         --percentage: 0;
//     }
//     100% {
//         --percentage: var(--value);
//     }
// }

// @property --percentage {
//     syntax: '<number>';
//     inherits: true;
//     initial-value: 0;
// }

// [role='progressbar'] {
//     --percentage: var(--value);
//     --primary: #369;
//     --secondary: #adf;
//     --size: 300px;
//     animation: progress 2s 0.5s forwards;
//     width: var(--size);
//     aspect-ratio: 2 / 1;
//     border-radius: 50% / 100% 100% 0 0;
//     position: relative;
//     overflow: hidden;
//     display: flex;
//     align-items: flex-end;
//     justify-content: center;
// }

// [role='progressbar']::before {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background: conic-gradient(
//         from 0.75turn at 50% 100%,
//         var(--primary) calc(var(--percentage) * 1% / 2),
//         var(--secondary) calc(var(--percentage) * 1% / 2 + 0.1%)
//     );
//     mask: radial-gradient(at 50% 100%, white 55%, transparent 55.5%);
//     mask-mode: alpha;
//     -webkit-mask: radial-gradient(at 50% 100%, #0000 55%, #000 55.5%);
//     -webkit-mask-mode: alpha;
// }

// [role='progressbar']::after {
//     counter-reset: percentage var(--value);
//     content: counter(percentage) '%';
//     font-family: Helvetica, Arial, sans-serif;
//     font-size: calc(var(--size) / 5);
//     color: var(--primary);
// }
.gap-summary {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 7px 0;
    padding: 7px;

    .gap-summary-bar {
        height: 10px;
        // border-radius: 3px;
        // border-radius: 50%;
        // transform: rotate(-90deg);

        cursor: pointer;
        width: 100%;
    }

    .gap-summary-grid {
        display: grid;
        width: 100%;
        height: 9px;
        margin: 0 7px;

        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

        .header {
            grid-column: span 4;
        }
        .edit {
            grid-column: span 1;
            text-align: right;

            [class*='ic-'] {
                width: 24px;
                height: 24px;
                display: inline-block;
                font-size: 12px;
                cursor: pointer;
            }

            .ic-gen-close {
                color: red;
                &:hover {
                    color: red;
                }
            }

            .ic-gen-check {
                margin-left: 0.5rem;
                color: green;
                &:hover {
                    color: greenyellow;
                }
            }
        }
        > div:not(.edit):not(.header) {
            height: 24px;
            position: relative;
        }

        div:not(.edit):not(.header) span {
            position: absolute;
            display: block;
            bottom: 0;
            left: 50%;
            margin-left: -3px;
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 50px solid gray;
        }

        [class*='grad-'] {
            position: relative;
            height: 10px;
            cursor: pointer;
            &:not(.grad-5) {
                border-right: 2px solid darkgrey;
            }
        }
    }

    .grad-1 {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        background-image: linear-gradient(to right, darkred, red);
    }
    .grad-2 {
        background-image: linear-gradient(to right, red, orange);
    }
    .grad-3 {
        background-image: linear-gradient(to right, orange, yellow);
    }
    .grad-4 {
        background-image: linear-gradient(to right, yellow, greenyellow);
    }
    .grad-5 {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        background-image: linear-gradient(to right, greenyellow, green);
    }

    .bg-gray {
        border-radius: 4px;
        background-color: lightgray;
        border: 2px solid gray;
    }
}

.gapAssessment {
    .paramRow {
        width: 100%;
        display: block;
        margin: auto;
    }
}

// Lever Dots
.greyDot {
    height: 13px;
    width: 13px;
    background: darkgrey;
    border-radius: 50%;
    display: inline-block;
}

.redDot {
    height: 13px;
    width: 13px;
    background: red;
    border-radius: 50%;
    display: inline-block;
}

.yellowDot {
    height: 13px;
    width: 13px;
    background-color: yellow;
    border-radius: 50%;
    display: inline-block;
}

.orangeDot {
    height: 13px;
    width: 13px;
    background-color: orange;
    border-radius: 50%;
    display: inline-block;
}

.greenDot {
    height: 13px;
    width: 13px;
    background-color: green;
    border-radius: 50%;
    display: inline-block;
}

.blueDot {
    height: 13px;
    width: 13px;
    background-color: rgb(85, 184, 249);
    border-radius: 50%;
    display: inline-block;
}

.coloredCircleParent {
    border-bottom: none;
}

// changes to the overall gap analysis page are made below.

.gapPage {
    html {
        height: 70vh;
        width: 80vw;
        margin-right: 45px;
        display: flex;
    }

    h3,
    h4,
    h6,
    p {
        margin: 0;
    }
    h3 {
        font-size: clamp(1rem, 1.2vw, 1.2rem);
    }
    h4 {
        font-size: clamp(1rem, 2vw, 1.1rem);
    }
    h6 {
        font-size: clamp(0.9rem, 2vw, 1rem);
    }
    p {
        font-size: clamp(0.8rem, 2vw, 1rem);
    }

    .MuiAccordionSummary-root {
        padding-left: 16px;
    }

    .css-o4b71y-MuiAccordionSummary-content {
        justify-content: space-between;
        width: 100%;
    }
}
.gapTable {
    min-width: 75vw;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    margin-left: 25px;

    .makeStyles-headerCSS-24 {
        font-size: 1rem;
    }
}

.goalsList {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
}

.css-1dedy8a-MuiPaper-root-MuiDialog-paper {
    height: 100%;
}
