// Table View

.evidencePillars {
    width: 1.2vw;
}

.inactiveEvidencePillars {
    width: 1.2vw;
    opacity: 0.4;
    color: #d3d5db;
}

.unmet-icon-path {
    fill: #ff0000;
}
// Legend for Left Panel View

.keyLegend {
    padding: 0;
    li {
        list-style: none;
    }
    .greenLegend {
        color: #07cc9e;
    }
    .yellowLegend {
        color: #fde541;
    }
    .orangeLegend {
        color: #ff9c2b;
    }
    .redLegend {
        color: #c30b31;
    }
    .blueLegend {
        color: #389bff;
    }
}
