.strategy-selection-background {
    display: flex;
    flex-flow: column;
    justify-content: center;

    h1 {
        font-size: clamp(1.2rem, 2.7vw, 1.6rem);
    }
    h2 {
        margin: 0;
        font-size: clamp(1.2rem, 2.2vw, 1.4rem);
    }
}


// body .strat-background {
//     background: url('../../assets/images/ATLAS_view_bg_white.jpg') no-repeat fixed center;
//     // height: 100vh;
// }
.strategy-section {
    display: flex;
    flex-wrap: wrap;
    margin: 75px;
}
.strategy-selection-box {
    height: 200px;
    width: 350px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 50px 0px;
}

.strategy-selection-box-selected {
    height: 200px;
    width: 325px;
    border: 1px solid rgba(85, 184, 249, 0.3);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 50px 0px;
}

.papers {
    display: flex;
    align-items: center;
    margin-right: 5px;
}
