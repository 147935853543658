.content {
    flex: 1;
    overflow: auto;
    justify-content: space-around;
}

a {
    text-decoration: none;
}

.displayFlex {
    display: flex;
    align-content: center;
}

.css-nuvkrp-MuiPaper-root-MuiDrawer-paper,
.css-2dum1v-MuiDrawer-docked {
    border-right: none !important;
}

.valueAccessSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.orgLogo {
    max-width: 150px;
    max-height: 50px;
}

.orgContainer {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
    p {
        font-size: clamp(1rem, 1vw, 1rem);
    }
}

.atlasLogo {
    max-width: 35px;
    min-width: 10px;
}

.justifiedRight {
    display: flex;
    align-items: center;
}

.strategyText {
    letter-spacing: 2px;
    margin: 0;
}

footer {
    text-align: center;
    width: 100%;
}
