.detailedView {
    .editBtn {
        align-items: center;
        display: flex;
        margin-bottom: 5px;
        .MuiFab-root {
            width: 40px !important;
            height: 40px !important;
            box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
                0px 1px 6px 0px rgba(0, 0, 0, 0.12) !important;
        }
    }

    .editBtnFab {
        margin-left: 5px;
        background: transparent;
        color: rgb(85, 184, 249);
        .MuiFab-root {
            width: 40px !important;
            height: 40px !important;
            box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
                0px 1px 6px 0px rgba(0, 0, 0, 0.12) !important;
        }
    }
}

.iconPanel {
    display: flex;
    height: 6vh;
    width: 100%;
    align-items: center;
}

.activityToggleGroup {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
}

.right_panel_detailed_view {
    li {
        list-style: none;
    }
}
// .MuiPaper-elevation3:hover {
//     border: 0.5px solid rgba(25, 110, 244, 0.5);
//     .arrowChange {
//         display: block;
//         color: rgb(25, 110, 244);
//     }
// }
.textBox {
    width: 100%;
    white-space: break-spaces;
}
.MuiBox-root-25 {
    padding: 0;
}

.top_support_counter {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    max-width: 100%;
    text-align: center;
    border-radius: 2px;
}

.pOverflow {
    overflow-y: auto;
}

.swipe_view {
    height: 60vh;
    margin-top: 15px;
    overflow-y: auto;
    width: 100%;
}

.MuiBox-root-32 {
    padding: 0 !important;
}

.initiatedDot {
    height: 10px;
    width: 10px;
    min-width: 10px;
    background-color: #0049ea;
    border-radius: 50%;
    display: inline-block;
}

.ongoingDot {
    height: 10px;
    width: 10px;
    min-width: 10px;
    background-color: #389bff;
    border-radius: 50%;
    display: inline-block;
}

.completedDot {
    height: 10px;
    width: 10px;
    min-width: 10px;
    background-color: #07cc9e;
    border-radius: 50%;
    display: inline-block;
}

.sanOngoingDot {
    height: 10px;
    width: 10px;
    min-width: 10px;
    background-color: #7A00E6;
    border-radius: 50%;
    display: inline-block;
}

.sanCompletedDot {
    height: 10px;
    width: 10px;
    min-width: 10px;
    background-color: #268500;
    border-radius: 50%;
    display: inline-block;
}

.knownDot {
    height: 10px;
    width: 10px;
    min-width: 10px;
    background-color: #0049ea;
    border-radius: 50%;
    display: inline-block;
}

.aspirationalDot {
    height: 10px;
    width: 10px;
    min-width: 10px;
    background-color: #389bff;
    border-radius: 50%;
    display: inline-block;
}

.defaultDot {
    height: 10px;
    width: 10px;
    min-width: 10px;
    background-color: #a5a5a5;
    border-radius: 50%;
    display: inline-block;
}

.knownDotDisplay {
    height: 15px;
    width: 15px;
    background-color: #0049ea;
    box-shadow: 0px 0px 15px #0049ea;
    border-radius: 50%;
    display: inline-block;
}

.aspirationalDotDisplay {
    height: 15px;
    width: 15px;
    background-color: #389bff;
    box-shadow: 0px 0px 15px #389bff;
    border-radius: 50%;
    display: inline-block;
}

.neutralDotDisplay {
    height: 15px;
    width: 15px;
    background-color: #959595;
    box-shadow: 0px 0px 15px #959595;
    border-radius: 50%;
    display: inline-block;
}

.greenLegend {
    height: 13px;
    width: 13px;
    background-color: #07cc9e;
    border-radius: 50%;
    display: inline-block;  
}

.yellowLegend {
    height: 13px;
    width: 13px;
    background-color: #fde541;
    border-radius: 50%;
    display: inline-block;  
}

.orangeLegend {
    height: 13px;
    width: 13px;
    background-color: #ff9c2b;
    border-radius: 50%;
    display: inline-block;  
}

.redLegend {
    height: 13px;
    width: 13px;
    background-color: #c30b31;
    border-radius: 50%;
    display: inline-block;  
}

.blueLegend {
    height: 13px;
    width: 13px;
    background-color: #389bff;
    border-radius: 50%;
    display: inline-block;  
}
