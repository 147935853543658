.button {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    border-radius: 100%;
    transition: 0.75s;

    &::before {
        content: ' ';
        position: absolute;

        height: inherit;
        width: inherit;
        border-radius: inherit;

        box-shadow:
            10px -10px 20px -5px rgba(0, 73, 234, 0.2),
            10px 10px 20px -7px rgba(255, 156, 43, 0.2),
            -10px 10px 20px -5px rgba(92, 52, 228, 0.2),
            -10px -10px 20px -7px rgba(228, 52, 155, 0.2);
        animation: rotate 6s linear infinite;
        transition: 1s;
    }

    &:hover:not(.loading) {
        opacity: 0.7;
        transform: scale(1.15);
    }

    &:not(.clickable) {
        cursor: default;
        pointer-events: none;
    }

    &.loading {
        cursor: wait;
        &::before {
            box-shadow:
                10px -10px 20px 0px rgba(0, 73, 234, 0.2),
                10px 10px 20px -2px rgba(255, 156, 43, 0.2),
                -10px 10px 20px 0px rgba(92, 52, 228, 0.2),
                -10px -10px 20px -2px rgba(228, 52, 155, 0.2);
            animation: rotate 3s linear infinite;
        }
    }

    & .icon {
        path {
            fill: #0049ea;
        }

        &.refresh {
            path {
                fill: transparent;
                stroke: #0049ea;
                stroke-width: 2.5px;
            }
        }

        &.loading {
            opacity: 0.7;
            animation: rotate 3s infinite;
        }

        &.generated {
            animation: wiggle 5s ease-in-out infinite alternate;
        }
    }
}

.button.dark {
    box-shadow:
        inset 1px 1px 6px #1f2126e6,
        inset 1px 1px 6px #2e333d;

    & .icon {
        path {
            fill: #55b8f9;
        }

        &.refresh {
            path {
                fill: transparent;
                stroke: #55b8f9;
            }
        }
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-359deg);
    }
}

@keyframes wiggle {
    from {
        transform: rotate(80deg);
    }
    to {
        transform: rotate(0deg);
    }
}
