// @import 'mixins';

.center-container {
    width: 100vw;
    height: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

// .loading-container {
//     position: relative;

//     .loading-symbol {
//         position: absolute;
//         background-image: url('/atlas-react/src/assets/images/symbol.svg');
//         height: 80px;
//         width: 80px;
//     }
// }
