.dashButton {
    border: 1px solid rgba(85, 184, 249, 0.2) !important;
    height: 50%;
}

.dashButton:hover {
    border: transparent !important;
}

.dashButton-delete {
    border: 1px solid rgb(226, 23, 23) !important;
    color: rgb(226, 23, 23) !important;
}

#tabAutoPaging {
    table,
    th,
    td {
        border: 1px solid black;
    }

    thead th {
        background-color: grey;
        color: red;
        border-bottom: #389bff;
    }

    tbody td {
        background-color: white;
        color: blue;
    }
}

.dots {
    display: flex;
    align-items: center;
    justify-content: center;
}

.displayFlex {
    display: flex;
    align-items: center;
}

.chartContainer {
    margin: 25px 70px;
    p {
        margin: 0;
    }
}

.countryFlag {
    border-radius: 50%;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
}

#no-vms {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='35' ry='35' stroke='%23DADADAFF' stroke-width='2' stroke-dasharray='12' stroke-dashoffset='78' stroke-linecap='round'/%3e%3c/svg%3e");
    border-radius: 35px;
    margin: 30px;
    padding: 7px;
    height: 30vh;
    text-align: center;
}

#no-vms > div {
    margin-top: 8vh;
}

.knownText {
    color: #0049ea;
}

.aspirationalText {
    color: #389bff;
}

.completedText {
    color: #07cc9e;
}
