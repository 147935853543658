body,
html {
    height: 100%;
    margin: 0;
    padding: 0;
}

html {
    box-sizing: border-box;
    font-size: 15px;
    font-family: acumin-pro, sans-serif !important;
    font-weight: 400;
    font-style: normal;
    --scrollbarBG: transparent;
    .MuiTableCell-root {
        font-family: 'acumin-pro', sans-serif;
        border: 'none'
    }

    .App {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
    }
}

body {
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

.checkboxSelector {
    margin: 10px;
}
